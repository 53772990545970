import axios from 'axios'
import {TOKEN} from './vars'
import {setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData} from './storage'

export function GET(url, token, download) {
  if(token && download) {
    return axios({
      url: url,
      method:'GET',
      headers: {
        'Authorization': 'Token '+ token
      },
      responseType: 'blob',
    })
  }
  else if (token){
    return axios({
      url: url,
      method:'GET',
      headers: {
        'Authorization': 'Token '+ token
      },
    })
  }
  else {
    return axios({
      url: url,
      method:'GET'
    })
  }
}


export function POST(url, token, data) {
  return axios({
    data: data,
    url:url,
    method:'POST',
    headers: {
      'Authorization': 'Token '+ token
    },
  })
}

export function UPOST(url, data) {
  return axios({
    url:url,
    data: data,
    method:'POST'
  })
}

export function UGET(url) {
  return axios({
    url:url,
    method:'GET'
  })
}